import createFormFocusDecorator from 'final-form-focus'
import { Op } from 'toggled'

export const channel = 'ecommerce'

export const DEFAULT = Symbol('default')

export const loginProviders = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
}

export const flags = {
  ADVANCED_FILTERS: 'advanced-filters',
  ALLOW_ADVERTISING: 'allow-advertising',
  ALLOW_APARTMENT_IN_ADDRESS: 'allow-apartment-in-address',
  ALLOW_REPURCHASE: 'allow-repurchase',
  BULK_ORDERING: 'bulk-ordering',
  CONTACT_PERSON: 'contact-person',
  COOKIES_NEED_USER_APPROVAL: 'cookies-need-user-approval',
  CUSTOMER_COMPLAINTS: 'customer-complaints',
  CUSTOMER_EXPORT_ORDERS: 'customer-export-orders',
  CUSTOMIZE_ORDER: 'customize-order',
  DEV_LINE_OF_BUSINESS: 'dev-line-of-business',
  DEV_ATTACH_PAYMENT_SUMMARY: 'dev-attach-payment-summary',
  EDIT_ORDER: 'edit-order',
  ENABLE_CODE_TO_LOGIN: 'enable-code-to-login',
  EXPRESS_DELIVERY: 'express-delivery',
  HANDLE_STOCK: 'handle-stock',
  HAS_REVIEW: 'has-review',
  HIDE_POWERED_BY_RIQRA: 'hide-powered-by-riqra',
  HIDE_PRICES: 'hide-prices',
  HIDE_SIGN_UP: 'hide-sign-up',
  LEGAL_AGE_MODAL: 'legal-age-modal',
  LINE_ITEMS_TOTAL_BREAKDOWN: 'line-items-total-breakdown',
  LINES_OF_CREDIT: 'lines-of-credit',
  LIST_STORES: 'list-stores',
  LOGIN_REQUIRED: 'login-required',
  MAP_IN_ADDRESS_CREATION: 'map-in-address-creation',
  MAP_WITH_GEOCODING: 'map-with-geocoding',
  MIXPANEL_TRACKING: 'mixpanel-tracking',
  MONTHLY_PURCHASE_LIMIT: 'monthly-purchase-limit',
  MULTI_DISTRIBUTOR_D2C: 'multi-distributor-d2c',
  MULTI_VENDOR_NAVIGATION: 'multi-vendor-navigation',
  MY_FAVORITES: 'my-favorites',
  NEEDS_TO_AGREE_TO_LEGAL_DOCUMENTS_IN_CHECKOUT_STEPS: 'needs-to-agree-to-legal-documents-in-checkout-steps',
  NEW_ADDRESS_CREATION_FLOW: 'new-address-creation-flow',
  NOT_AVAILABLE: 'not-available',
  DISABLE_COVERAGE_HEADER: 'disable-coverage-header',
  OMIT_INVOICING: 'omit-invoicing',
  ONE_CLICK_ORDER: 'one-click-order',
  ORDER_COMMENTS: 'order-comments',
  ORDER_NOTE: 'order-note',
  ORDER_REVIEW: 'order-review',
  PAYMENT_METHOD_BANNER: 'payment-method-banner',
  PAYMENT_METHOD: 'payment-method',
  PICKUP_DATES: 'pickup-dates',
  PRE_SIGN_UP: 'pre-sign-up',
  PRICE_QUOTATION_NAVIGATION: 'price-quotation-navigation',
  PRODUCT_SORTING: 'product-sorting',
  PUBLIC_PARTNER_VENDOR: 'public-partner-vendor',
  RELATED_PRODUCTS: 'related-products',
  ATTACH_DOCUMENTS: 'attach-documents',
  SELECT_DELIVERY_TIME_RANGE: 'select-delivery-time-range',
  SELECT_PICKUP_TIME_RANGE: 'select-pickup-time-range',
  SHIPPING_BEFORE_BUY: 'shipping-before-buy',
  SHIPPING_BEFORE_SEE_PRODUCTS: 'shipping-before-see-product',
  SHOW_CART_IN_MAIN_LAYOUT: 'show-cart-in-main-layout',
  SHOW_CURRENCY_CODE: 'show-currency-code',
  SHOW_PRICE_AFTER_LOGIN: 'show-price-after-login',
  SHOW_PRODUCT_STOCK: 'show-product-stock',
  SHOW_DISCOUNT_LABEL: 'show-discount-label',
  SHOW_SKU_LABEL: 'show-sku-label',
  SHOW_TAX_LABEL: 'show-tax-label',
  SKIP_DATE_PICKER_IN_SHIPPING: 'skip-date-picker-in-shipping',
  SORT_PRODUCT_ATTRIBUTE_VALUES: 'sort-product-attribute-values',
  STORE_NAVIGATION: 'store-navigation',
  SUGGESTED_PRODUCTS_MODAL: 'suggested-products-modal',
  TERRITORY_GEOFENCES: 'territory-geofences',
  TG_QUOTE: 'tg-quote',
  UNDER_CONSTRUCTION_MODAL: 'under-construction-modal',
  UNITS: 'units',
  UPLOAD_PURCHASE_ORDER: 'upload-purchase-order',
}

export const flagQueries = {
  HAS_FULL_WIDTH: { [flags.SHOW_CART_IN_MAIN_LAYOUT]: false },
  CAN_SELECT_TIME_RANGE: {
    [Op.OR]: [flags.SELECT_DELIVERY_TIME_RANGE, flags.SELECT_PICKUP_TIME_RANGE],
  },
}

export const shippingTypes = {
  CHAZKI: 'chazki',
  DELIVERY: 'delivery',
  MIENVIO: 'mienvio',
  PICKUP_IN_STORE: 'pickup-in-store',
  EXPRESS_DELIVERY: 'express-delivery',
  DELIVERY_COURIER: 'delivery-courier',
  PICKUP_IN_COURIER: 'pickup-in-courier',
}

export const documentTypes = {
  BILL: 'bill',
  TICKET: 'ticket',
}

export const paymentMethods = {
  CULQI: 'culqi',
  CULQI_JS_YAPE: 'culqi-js-yape',
  CULQI_JS_CARD: 'culqi-js-card',
  CULQI_CUOTEALO: 'culqi-cuotealo',
  VISANET: 'visanet',
  KUSHKI: 'kushki',
  OPENPAY: 'openpay',
  DEPOSIT: 'deposit',
  CASH: 'cash',
  LINE_OF_CREDIT: 'line-of-credit',
  ZONAPAGOS: 'zona-pagos',
  MERCADO_PAGO: 'mercado-pago',
  IZIPAY: 'izipay',
  WEBPAY: 'webpay',
  PAY_ME: 'pay-me',
  FINTOC: 'fintoc',
  FREE: 'free',
}

export const transformations = {
  CATEGORY: 'h_288,c_limit',
  DESKTOP_BANNER: 'w_2700,h_675,c_limit',
  GALLERY_FULL_IMAGE: 'w_906,h_906,c_fit',
  GALLERY_THUMBNAIL: 'w_128,h_128,c_limit',
  INTEGRATION_LOGO: 'h_128,w_128',
  LOGO: 'h_112,c_limit',
  MOBILE_BANNER: 'w_1080,h_540,c_limit',
  OG_CATEGORY_IMAGE: 'w_1200,h_1200,c_limit',
  OG_MAIN_IMAGE: 'w_1200,h_630,c_limit',
  PAYMENT_METHOD_LOGO: 'h_32,c_limit',
  POP_UP: 'w_500,c_limit',
  PRIVATE_LOGIN_BG: 'w_1440,h_1440,c_limit',
  PRODUCT_SLIDER: 'w_656,h_656,c_limit',
  PRODUCT: 'h_380,c_limit',
  RATING_IMAGE: 'h_32,c_limit',
  TINY_LOGO: 'w_auto,h_80,c_limit',
  TINY_PRODUCT: 'w_240,h_240,c_limit',
  TRACK_ICON: 'c_scale,h_24,w_24',
  VISANET_MERCHANT_LOGO: 'w_240,h_100,c_lpad',
}

// the cookie version name was introduced after some clients
// had an invalid value for its token
export const cookieNames = {
  ACCEPTED_COOKIES: 'accepted-cookies',
  CALLBACK_URL: 'callback-url',
  ENDPOINT: 'endpoint',
  EXPRESS_DELIVERY_SESSION: 'express-delivery-session',
  LEGAL_AGE_MODAL_CLOSED: 'legal-age-modal-closed',
  MULTI_DISTRIBUTOR_SESSION: 'multi-distributor-session',
  PARTNER_VENDOR_ENDPOINT: 'partner-vendor-endpoint',
  SESSION: 'session-v1.2',
  STORE_ID: 'store-id',
}

export const storageKeys = {
  ADDRESS_COMPONENTS: 'address-components',
  MAIN_POP_UP: 'main-pop-up',
}

export enum checkoutSteps {
  CUSTOMIZATION = 'personalizar',
  SHIPPING = 'envio',
  PURCHASE_ORDER = 'orden-de-compra',
  INVOICING = 'recibo',
  PAYMENT = 'pago',
}

export const funnelSteps = {
  [`/comprar/${checkoutSteps.SHIPPING}`]: 1,
  [`/comprar/${checkoutSteps.INVOICING}`]: 2,
  [`/comprar/${checkoutSteps.PAYMENT}`]: 3,
}

export const jobStates = {
  COMPLETED: 'completed',
  FAILED: 'failed',
}

export const baseUrls = {
  CLOUDINARY: 'https://res.cloudinary.com/riqra/image/upload',
  GOOGLE_MAPS: 'https://maps.googleapis.com/maps/api/js?libraries=places',
}

export const images = {
  ERROR_VENDOR_NOT_AVAILABLE: `${baseUrls.CLOUDINARY}/v1623446567/sellers/resources/error-vendor-not-available.svg`,
  ILLUSTRATION_CHECK: `${baseUrls.CLOUDINARY}/v1603387613/sellers/resources/check.png`,
  ILLUSTRATION_EMPTY_CART: `${baseUrls.CLOUDINARY}/v1580771172/sellers/resources/empty-cart.svg`,
  ILLUSTRATION_EMPTY_ORDERS: `${baseUrls.CLOUDINARY}/v1580772279/sellers/resources/empty-items.svg`,
  ILLUSTRATION_ERROR: `${baseUrls.CLOUDINARY}/v1603388139/sellers/resources/error.png`,
  ILLUSTRATION_FAVORITE: `${baseUrls.CLOUDINARY}/v1616602861/sellers/resources/favorite.svg`,
  ILLUSTRATION_FILE_ALERT: `${baseUrls.CLOUDINARY}/v1619805474/sellers/resources/illustration-file-alert.svg`,
  ILLUSTRATION_ONLINE_SHOP: `${baseUrls.CLOUDINARY}/v1645748904/sellers/resources/online-shop.svg`,
  ILLUSTRATION_PAPER_PROBLEM: `${baseUrls.CLOUDINARY}/v1600269083/sellers/resources/paper-problem.svg`,
  ILLUSTRATION_PROCESSING_ORDER: `${baseUrls.CLOUDINARY}/v1560784665/sellers/order-processing.png`,
  ILLUSTRATION_RATING: `${baseUrls.CLOUDINARY}/v1662948986/sellers/resources/rating.svg`,
  ILLUSTRATION_SHOPPING_CART: `${baseUrls.CLOUDINARY}/v1628221613/sellers/resources/shopping-cart.svg`,
  ILLUSTRATION_WARNING: `${baseUrls.CLOUDINARY}/v1651080601/sellers/resources/warning.png`,
  PRODUCT_PLACEHOLDER: `${baseUrls.CLOUDINARY}/v1534706090/saas/resources/placeholders/product.png`,
  RIQRA_FAVICON_BLUE: `${baseUrls.CLOUDINARY}/v1623782549/saas/resources/brand/favicon-blue.png`,
  RIQRA_LOGOTYPE_WHITE: `${baseUrls.CLOUDINARY}/v1568134068/saas/resources/brand/logotype-white.svg`,
}

export const orderTypes = {
  ORDER: 'order',
  QUOTATION: 'quotation',
}

export const orderStatuses = {
  TO_BE_APPROVED: 'to-be-approved',
  TO_BE_CONFIRMED: 'to-be-confirmed',
  TO_BE_PAID: 'to-be-paid',
  REGISTERED: 'registered',
  PAID: 'paid',
  CANCELED: 'canceled',
}

export const dateFormats = {
  date: 'DD[/]MM[/]YYYY',
  time: 'hh[:]mm A',
  dateHumanized: 'DD [de] MMMM [de] YYYY',
  monthOnly: 'MMMM',
  get dateTime() {
    return `${this.date} ${this.time}`
  },
}

export const timeouts = {
  ANALYTICS_EVENT: 500,
  REDIRECTION: 1000,
}

export const messagesRatingWhenHovering = [
  {
    icon: 'bad',
    color: 'error',
    label: 'Malo',
    background: 'errorBg',
  },
  {
    icon: 'regular',
    color: 'grayDark',
    label: 'Regular',
    background: 'grayDarkBg',
  },
  {
    icon: 'great',
    color: 'warning',
    label: '¡Está bien!',
    background: 'warningBg',
  },
  {
    icon: 'excellent',
    color: 'success',
    label: '¡Bueno!',
    background: 'successBg',
  },
  {
    icon: 'excellent',
    color: 'success',
    label: '¡Excelente!',
    background: 'successBg',
  },
]

export const ratingColor = '#FFD027'

export const fileSizeLimits = {
  PURCHASE_ORDER: 1024 * 1024 * 5,
  CUSTOMER_COMPLAINT_ATTACHMENT: 1024 * 1024 * 2,
  ATTACH_DOCUMENTS: 1024 * 1024 * 10,
}

export const allowedFileExtensions = {
  PURCHASE_ORDER: ['pdf', 'jpg', 'jpeg', 'png'],
  CUSTOMER_COMPLAINT_ATTACHMENT: 'application/pdf, .jpg, .jpeg, .png',
  ATTACH_DOCUMENTS: 'application/pdf, .jpg, .jpeg, .png',
}

export const uploadFolders = {
  CUSTOMER_COMPLAIN_ATTACHMENTS: 'customer-complaint-attachments',
  REBATE_INVOICES: 'rebate-invoices',
}

export const events = {
  BULK_ORDERING_MODAL_CLOSED: 'Bulk Ordering Modal Closed',
  BULK_ORDERING_MODAL_OPENED: 'Bulk Ordering Modal Opened',
  BULK_ORDERING_PROCESS_FINISHED: 'Bulk Ordering Process Finished',
  BULK_ORDERING_PROCESS_STARTED: 'Bulk Ordering Process Started',
  DOWNLOAD_QUOTATION: 'Download Quotation',
  LOGIN_FROM_THE_CONFIRMATION_MODAL: 'Login From the Confirmation Modal',
  MY_FAVORITES_PAGE_VISITED: 'My Favorites Page Visited',
  PRODUCT_ADDED_TO_CART_FROM_MY_FAVORITES_PAGE: 'Product Added to Cart from My Favorites Page',
  PRODUCT_ADDED_TO_FAVORITES: 'Product Added to Favorites',
  PRODUCT_REMOVED_FROM_FAVORITES: 'Product Removed from Favorites',
  PRODUCTS_ADDED_TO_CART_FROM_LAST_ORDER_REPURCHASE: 'Products Added To Cart From Last Order Repurchase',
  PRODUCTS_ADDED_TO_CART_FROM_MOST_PURCHASED_PRODUCTS: 'Products Added To Cart From Most Purchased Products',
}

export const riqraInfo = {
  favicon: images.RIQRA_FAVICON_BLUE,
  helpCenter: 'https://help.riqra.com',
  website: 'https://riqra.com',
}

export const countries = {
  CL: 'CL',
}

/**
 * @description Lima Center Coordinates
 */
export const defaultCoordinates = {
  lat: -12.04321,
  lng: -77.0451897,
}

export const defaultFinalFormDecorators = [createFormFocusDecorator()]

export const defaultTaxLabel = 'IGV'

export const analyticEvents = {
  THIRD_PARTY_ASSETS_FAILED: 'third_party_assets_failed',
  PROVIDER_FAILED: 'provider_failed',
}

export const analyticsCreativeSlot = {
  MAIN_BANNERS: 'main_banners',
  MAIN_ANNOUNCEMENT_BAR: 'main_announcement_bar',
}

// this constant contain default messages for flags or messages received from database if undefined
export const defaultMessages = {
  SKIP_DATE_PICKER_DEFAULT_MESSAGE: 'La fecha de envío o recojo será definida posteriormente',
}

export const keyCodes = {
  ENTER: 13,
}

export const timingDelays = {
  ADD_PRODUCT_TO_CART: 900,
}

export const analyticsItemListContexts = {
  FEATURED_PRODUCTS: { itemListId: 'featured_products', itemListName: 'Featured products' },
  FAVORITE_PRODUCTS: { itemListId: 'favorite_products', itemListName: 'Favorite products' },
  RELATED_PRODUCTS: { itemListId: 'related_products', itemListName: 'Related products' },
  SUGGESTED_PRODUCTS: { itemListId: 'suggested_products', itemListName: 'Suggested products' },
  SEARCH_PAGE: { itemListId: 'search_page', itemListName: 'Search page' },
  CATEGORY_PAGE: { itemListId: 'category_page', itemListName: 'Category page' },
  MOST_PURCHASED_PRODUCTS: { itemListId: 'most-purchased_products', itemListName: 'Most purchased products' },
  LAST_ORDER: { itemListId: 'last_order', itemListName: 'Last order' },
}

export const sessionStorageKeys = {
  SUGGESTED_PRODUCT_MODAL_DISABLE: 'suggested_product_modal_disable',
}

export const cartMinimumOrderTooltip = {
  PRODUCTS: 'El monto mínimo evalúa el valor de los productos',
  SUBTOTAL: 'El monto mínimo evalúa el valor de los productos menos descuentos',
  TOTAL: 'El monto mínimo evalúa el valor de los productos menos descuentos más impuestos',
}

export const httpStatusCodes = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
}

export const quotationStatuses = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
}
