import React from 'react'
import { useFeature, useFlag } from 'toggled'

import AddressMap from '~/truck/address-map'
import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import TextGroup from '~/truck/text-group'
import theme from '~/truck/theme'
import { defaultMessages, orderTypes, shippingTypes } from '~/utils/constants'
import { flags } from '~/utils/constants'

import Heading from '../heading'
import Panel from '../panel'
import Placeholder from '../placeholder'

import { InnerPanelContent, StyledContactPersonContainer, TextGroupsContainer } from './elements'
import getInfo from './get-info'
import { getContactPersonNameAndDocument } from './utils'

const ShipmentInfo = props => {
  const { fare, tracking } = props

  const trackingCodeElement = !tracking ? (
    'Aún no disponible'
  ) : (
    <a href={tracking.providerUrl} target="_blank" rel="noopener noreferrer">
      {tracking.originalId}
    </a>
  )

  return (
    <TextGroupsContainer>
      {fare && (
        <>
          <TextGroup title="Método de entrega" lines={[fare.serviceLevel]} />
          <TextGroup title="Fecha de entrega" lines={[fare.durationTerms]} />
          <TextGroup title="Paqueteria" lines={[fare.provider]} />
        </>
      )}
      {tracking && <TextGroup title="Código de seguimiento" lines={[trackingCodeElement as string]} />}
    </TextGroupsContainer>
  )
}

const deliveryContactPersonTitle = 'Contacto en la entrega'

const Content = props => {
  const { order, onEmptyClick, customMessage, hasContactPersonFeature } = props

  const hasEmptyState = !order.store && !order.deliveryAddress && !order.extraFields?.courierBranchId

  if (hasEmptyState) {
    return <Placeholder text="Seleccionar entrega" onClick={onEmptyClick} />
  }

  const info = getInfo(order)

  const isExpressDelivery = order.shippingType.slug === shippingTypes.EXPRESS_DELIVERY

  const isDelivery = order.shippingType.isDelivery

  const contactPersonInfo = info.contactPersonInfo ?? ({} as any)

  const allContactPersonFieldsAreNull = Object.values(contactPersonInfo).every(value => !value)

  return (
    <>
      <Heading iconName={info.iconName} title={info.title}>
        {order.shipmentTrackingUrl && (
          <Anchor href={order.shipmentTrackingUrl} target="_blank">
            <Label as="p" $textStyle="h5Regular" $color="info">
              Seguir envío
            </Label>
          </Anchor>
        )}
      </Heading>
      <AddressMap reference={info.reference} apartment={info.apartment} address={info.address} />
      <InnerPanelContent>
        {order.shipment && !isExpressDelivery && <ShipmentInfo orderId={order.id} {...order.shipment} />}
        {order.transactionType === orderTypes.ORDER && (info.deliveryDate || customMessage) && (
          <TextGroup title={info.deliveryDateTitle} lines={[info.deliveryDate ?? customMessage]} />
        )}
        {info.deliveryTime && (
          <TextGroup
            title={info.deliveryTimeTitle}
            lines={[info.deliveryTime]}
            style={{ marginTop: theme.spacing.comfortable }}
          />
        )}
        {!allContactPersonFieldsAreNull && (
          <StyledContactPersonContainer>
            <Label $textStyle="h6Semibold">
              {!hasContactPersonFeature && isDelivery ? deliveryContactPersonTitle : info.contactPersonTitle}
            </Label>
            <Label $textStyle="h5Regular">{getContactPersonNameAndDocument(contactPersonInfo)}</Label>
            <Label $textStyle="h5Regular">{contactPersonInfo?.phone}</Label>
          </StyledContactPersonContainer>
        )}
      </InnerPanelContent>
    </>
  )
}

const Shipping = props => {
  const { className, style, isEditable, onEditClick, order } = props

  const skipDatePickerFF = useFeature(flags.SKIP_DATE_PICKER_IN_SHIPPING)

  const customMessage = skipDatePickerFF?.settings?.customMessage ?? defaultMessages.SKIP_DATE_PICKER_DEFAULT_MESSAGE

  const hasContactPersonFeature = useFlag(flags.CONTACT_PERSON)

  return (
    <Panel
      title="Datos de entrega"
      className={className}
      style={style}
      isEditable={isEditable}
      onEditClick={onEditClick}
    >
      <Content order={order} customMessage={customMessage} hasContactPersonFeature={hasContactPersonFeature} />
    </Panel>
  )
}

export default Shipping
